import { BigNumber } from 'bignumber.js'
import React, { FC } from 'react'

import { financialFormat } from '../../helpers/priceAndAmountHelpers'
import { TokenV3 } from '../../model'
import { getMarketDisplayName, isTitleNaN } from '../../utils'

interface FormRatesProps {
  ratioQuotePrice?: BigNumber
  tokenFrom?: TokenV3
  tokenTo?: TokenV3
  isEmpty?: boolean
}

const FormRates: FC<FormRatesProps> = ({
  ratioQuotePrice,
  tokenFrom,
  tokenTo,
  isEmpty,
}: FormRatesProps) => {
  if (isEmpty || !ratioQuotePrice) {
    return null
  }

  const ratio: number = ratioQuotePrice.toNumber()
  return (
    <div className="tradeform-field__rates">
      1&nbsp;
      <span className="sign">{getMarketDisplayName(tokenFrom)}</span>
      &nbsp;=&nbsp;
      <span title={isTitleNaN(financialFormat(ratio))}>{financialFormat(ratio)}</span>
      &nbsp;
      <span className="sign">{getMarketDisplayName(tokenTo)}</span>
      &nbsp;=&nbsp;
      <span>{financialFormat(ratioQuotePrice.multipliedBy(tokenTo?.priceUSD || 0))}</span>
      &nbsp;
      <span className="sign">USD</span>
    </div>
  )
}

export default FormRates
