import BigNumber from 'bignumber.js'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { financialFormat } from '../../helpers/priceAndAmountHelpers'
import {
  TokenV3,
  TokenWithApprovalContext,
  TradeformType,
  TradeType,
  Transaction,
} from '../../model'
import { State } from '../../reducers'
import { calculateDeltaUsdPrice, calculateTokenUsdPrices } from '../../services/tokenService'
import {
  getInlineNetworkIconStyle,
  getMarketDisplayName,
  getNetworkConfig,
  isTitleNaN,
  numberWithCommas,
} from '../../utils'
import AnimatedInput from '../AnimatedInput'
import DropdownForm from '../DropdownForm'
import IconTokenWrapper from '../IconTokenWrapper'
import FormRates from './FormRates'
import { FormVariants } from './helpers'

interface FormToProps {
  tokenTo: TokenV3
  tokenFrom: TokenV3
  tradeType: TradeType
  isEmpty: boolean
  fromAmount?: BigNumber
  readOnly?: boolean
  onChange?: (value: string) => void
  isShowRate?: boolean
  formVariant: TradeformType
}

const FormTo: React.FC<FormToProps> = ({
  tokenFrom,
  tokenTo,
  tradeType,
  isEmpty,
  fromAmount,
  onChange,
  formVariant,
  readOnly = true,
  isShowRate = true,
}) => {
  const [isDropDownOpened, setIsDropDownOpened] = useState<boolean>(false)
  const [toTokenUsdPrice, setToTokenUsdPrice] = useState(new BigNumber(0))
  const [deltaTokenUsdPrice, setDeltaTokenUsdPrice] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [estimatedTo, setEstimatedTo] = useState<BigNumber | undefined>(new BigNumber(0))

  const [isFocus, setIsFocus] = useState(false)

  const currentToken: TokenWithApprovalContext | undefined = useSelector(
    (state: State) => state.tokens.currentToken
  )

  const txn: Transaction = useSelector((state: State) => state.txn[tradeType])

  useEffect(() => {
    setIsLoading(Boolean(txn.estimateLoading))
    setEstimatedTo(txn.estimatedAmount)
  }, [txn.estimateLoading, txn.estimatedAmount])

  useEffect(() => {
    if (!txn.estimateLoading) {
      let fromAmountSelectedCurrency = new BigNumber(0)
      let toAmountSelectedCurrency = new BigNumber(0)
      if (fromAmount?.gt(0) && txn.estimatedAmount) {
        const tokenUsdPrice = calculateTokenUsdPrices({
          tokenFrom,
          tokenTo,
        })
        fromAmountSelectedCurrency = fromAmount.multipliedBy(tokenUsdPrice.fromTokenUsdPrice)
        toAmountSelectedCurrency = txn.estimatedAmount.multipliedBy(tokenUsdPrice.toTokenUsdPrice)
      }
      const currentDelta = calculateDeltaUsdPrice({
        fromAmountSelectedCurrency,
        toAmountSelectedCurrency,
      })

      setDeltaTokenUsdPrice(currentDelta)
      setToTokenUsdPrice(toAmountSelectedCurrency)
    }
  }, [tokenFrom, tokenTo, fromAmount, txn.estimatedAmount, txn.estimateLoading, tokenTo.priceUSD])

  const getValue = (): string => {
    if (isEmpty) {
      return ''
    }

    if (txn.estimateLoading) {
      return ''
    }

    return financialFormat(estimatedTo || '', {
      highPrecession: true,
      decimals: estimatedTo?.lt(1) ? 7 : 4,
    })
  }

  if (!currentToken?.id) {
    return null
  }

  const networkConfig = getNetworkConfig(currentToken.network)
  const styleIcon = getInlineNetworkIconStyle(currentToken?.network, networkConfig)
  const title = formVariant === FormVariants.market ? 'To' : 'You Receive'
  return (
    <div className="tradeform-field tradeform-field--to">
      <div className="tradeform-field__header">
        <div className="tradeform-field__title">
          <span>{title}</span>
        </div>
        {isShowRate && (
          <div className="tradeform-field__value">
            <FormRates
              ratioQuotePrice={txn.price}
              tokenFrom={tokenFrom}
              tokenTo={tokenTo}
              isEmpty={isEmpty}
            />
          </div>
        )}
      </div>
      <div
        className={classNames('tradeform-field__body', {
          'tradeform-field__body--selector': tradeType === 'Sell',
        })}>
        <div
          className={classNames('tradeform-combo', {
            'tradeform-combo--open': isDropDownOpened,
            'tradeform-combo--paired': isFocus,
            'tradeform-combo--focus': isFocus,
            'tradeform-combo--selector': tradeType === 'Sell',
          })}>
          {tradeType !== 'Sell' ? (
            <div className="tradeform-combo__token">
              <div className="tradeform-token">
                <div
                  className={classNames(
                    'tradeform-token__icon',
                    'token-ico',
                    'token-ico--sm',
                    tokenTo?.network ? `token-ico--network-${tokenTo?.network}` : ''
                  )}
                  style={styleIcon}>
                  {tokenTo?.id && (
                    <IconTokenWrapper
                      logoURI={tokenTo.logoURI}
                      symbols={tokenTo.symbols}
                      className="token-ico__image"
                    />
                  )}
                </div>
                {tokenTo?.id && (
                  <span className="tradeform-token__name" title={getMarketDisplayName(tokenTo)}>
                    <span className="caption">{getMarketDisplayName(tokenTo)}</span>
                  </span>
                )}
              </div>
            </div>
          ) : (
            <DropdownForm
              tradeType={tradeType}
              onDropDownStateChange={setIsDropDownOpened}
              network={currentToken.network}
              isChangeQuoteToken
              tradeformType={formVariant}
              token={tokenTo}
            />
          )}
          <div className="tradeform-combo__query">
            {isLoading ? (
              <AnimatedInput />
            ) : (
              <>
                {formVariant === FormVariants.market && toTokenUsdPrice?.gt(0) && (
                  <div className="tradeform-field__price">
                    {financialFormat(toTokenUsdPrice, { prefix: '~$' })}
                    &nbsp;
                    {deltaTokenUsdPrice && (
                      <span
                        className={classNames('percent', {
                          'percent--gain': deltaTokenUsdPrice > 0,
                          'percent--loss': deltaTokenUsdPrice <= 0,
                        })}>
                        ({financialFormat(deltaTokenUsdPrice, { decimals: 2 })}%)
                      </span>
                    )}
                  </div>
                )}
                <input
                  className="tradeform-field__input"
                  readOnly={readOnly}
                  type="text"
                  placeholder="0.0"
                  title={isTitleNaN(numberWithCommas(estimatedTo && estimatedTo.toFixed()))}
                  value={getValue()}
                  data-testid="tradeform-input-to"
                  onBlur={(): void => setIsFocus(false)}
                  onFocus={(): void => setIsFocus(true)}
                  onChange={({ target }): void => {
                    onChange && onChange(target.value)
                  }}
                />
              </>
            )}
          </div>
        </div>
      </div>
      <div className="tradeform-field__footer">
        {formVariant === FormVariants.market && txn.sourcesString && !isEmpty ? (
          <span className="tradeform-field__via">
            Via&nbsp;<span className="sign">{txn.sourcesString}</span>
          </span>
        ) : (
          <span className="tradeform-field__via">&nbsp;</span>
        )}
      </div>
    </div>
  )
}

export default FormTo
